// extracted by mini-css-extract-plugin
export var animatedWrapper = "insurtech-software-module--animatedWrapper--c2442";
export var banner = "insurtech-software-module--banner--ddfd9";
export var banner__img = "insurtech-software-module--banner__img--2db89";
export var banner__linksWrapper = "insurtech-software-module--banner__linksWrapper--7de79";
export var billetShadow = "insurtech-software-module--billet-shadow--6fa21";
export var caseImg = "insurtech-software-module--caseImg--404de";
export var caseImg__top = "insurtech-software-module--caseImg__top--75b6b";
export var caseList = "insurtech-software-module--caseList--aee9b";
export var caseText = "insurtech-software-module--caseText--c55b9";
export var cases = "insurtech-software-module--cases--5d9cf";
export var colorScheme__background__grayPale = "insurtech-software-module--colorScheme__background__gray-pale--f1085";
export var headline = "insurtech-software-module--headline--26137";
export var headline_grid = "insurtech-software-module--headline_grid--68799";
export var icomoon = "insurtech-software-module--icomoon--27c4e";
export var imgButton = "insurtech-software-module--imgButton--098b3";
export var insurTechBlock = "insurtech-software-module--insurTechBlock--e38fc";
export var lowercase = "insurtech-software-module--lowercase--ecf43";
export var positionedText = "insurtech-software-module--positionedText--8ad8a";
export var services = "insurtech-software-module--services--2da82";
export var smallCard = "insurtech-software-module--smallCard--b0c97";
export var transition = "insurtech-software-module--transition--9d404";
export var transitionBackground = "insurtech-software-module--transition-background--ed3a9";
export var transitionReverse = "insurtech-software-module--transition-reverse--4c643";
export var uppercase = "insurtech-software-module--uppercase--e147e";